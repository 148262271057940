import Component from '@averyano/core/src/classes/Component';
import GSAP from 'gsap';
import Hamburger from './Hamburger';
import each from 'lodash/each';
// import NodeEmitter from '../classes/NodeEmitter';

export default class Navigation extends Component {
	constructor() {
		super({
			element: 'nav',
			elements: {
				content: '.nav__content',
				contentLinksMetrics: '.nav__content__link',
				logoLinkMetrics: '.nav__logo',
				contentLinks: '.nav__content__link--inner',
				menuItem: '.nav__menu',
				emailItems: '.nav__email__item',
				emailLinks: '.nav__email__link',
				menuLogoMain: '.nav__logo__svg--main',
				menuLogoPath: '.nav__logo__svg--main path',
				logoItem: '.nav__logo__img',
				coverUi: '.cover__ui__bottom',
				langSwitch: '.nav__lang-switch',
				langCurrent: '.nav__lang-current',
				langOther: '.nav__lang-other',
				langOtherSvg: '.nav__lang-other svg',
			},
		});
		this.isOpen = false;
		this.template = null;
		this.isLangOpen = false;

		this.colors = {
			black: 'rgb(17, 17, 17)',
			stone: 'rgb(209, 201, 194)',
		};

		this.elements.coverUi.classList.add('white');

		this.createTimeline();
		this.createHamburgerIcon();

		this.addEventListeners();
		this.onResize();
		this.$on('navChangeIndex', (index) => this.navChangeIndex(index));
	}

	/**
	 * HAMBURGER RELATED
	 */

	navChangeIndex(index) {
		each(this.elements.contentLinks, (link) => link.classList.remove('active'));
		this.elements.contentLinks[index].classList.add('active');

		if (index === 0) {
			document.title = 'Utkina Design — Works';
		}
		if (index === 1) {
			document.title = 'Utkina Design — Services';
		}
		if (index === 2) {
			document.title = 'Utkina Design — Awards & Education';
		}
		if (index === 3) {
			document.title = 'Utkina Design — Contact';
		}
	}

	createHamburgerIcon() {
		this.hamburger = new Hamburger();
		this.hamburger.create();
	}

	openMenu() {
		this.isOpen = true;

		this.elements.menuLogoMain.classList.add('dark');
		each(this.elements.emailLinks, (item) => item.classList.add('dark'));
		each(this.elements.emailItems, (item) => item.classList.add('open'));
		this.elements.menuItem.classList.add('open');

		GSAP.to(this.elements.emailItems, {
			color: '#676767',
		});

		GSAP.to(this.elements.menuLogoPath, {
			fill: 'rgb(206, 203, 190)',
		});

		GSAP.to(this.elements.content, {
			autoAlpha: 1,
			onComplete: () => (this.hamburger.isAnimating = false),
		});

		GSAP.from(this.elements.contentLinks, {
			x: -1000,
			stagger: 0.1,
			duration: 1,
			ease: 'power4.out',
		});
	}

	closeMenu() {
		this.isOpen = false;

		this.elements.menuLogoMain.classList.remove('dark');
		each(this.elements.emailLinks, (item) => item.classList.remove('dark'));
		each(this.elements.emailItems, (item) => item.classList.remove('open'));
		this.elements.menuItem.classList.remove('open');

		GSAP.to(this.elements.emailItems, {
			color: this.colors.black,
		});

		GSAP.to(this.elements.menuLogoPath, {
			fill: this.colors.black,
			onComplete: () => (this.hamburger.isAnimating = false),
		});

		GSAP.to(this.elements.content, {
			autoAlpha: 0,
		});
	}

	closeAll() {
		this.closeMenu();

		if (this.hamburger.isOpen) this.hamburger.toggleState();
	}
	/**
	 * MENU ELEMENTS RELATED
	 */
	// @TODO

	/**
	 * NAV RELATED
	 */
	createTimeline() {
		// this.enterTl = GSAP.timeline();

		// this.enterTl.fromTo(
		// 	this.elements.logoOverlay,
		// 	{
		// 		opacity: 0,
		// 	},
		// 	{
		// 		opacity: 1,
		// 		duration: 0.5,
		// 		ease: 'out.expo',
		// 	},
		// 	0
		// );

		// this.leaveTl = GSAP.timeline();

		// this.leaveTl.fromTo(
		// 	this.elements.logoOverlay,
		// 	{
		// 		opacity: 1,
		// 	},
		// 	{
		// 		opacity: 0,
		// 		duration: 0.2,
		// 		stagger: 0.1,
		// 		ease: 'out.expo',
		// 	},
		// 	0
		// );

		// this.enterTl.pause();
		// this.leaveTl.restart();

		this.langTl = GSAP.timeline({
			duration: 1,
			ease: 'power4.out',
			paused: true,
		});

		this.langTl.fromTo(
			this.elements.langOther,
			{
				autoAlpha: 0,
				borderRadius: '8 8 8 8',
			},
			{
				autoAlpha: 0.9,
				duration: 1.3,
				borderRadius: '0 0 8 8',
				ease: 'power4.out',
			},
			0
		);

		this.langTl.fromTo(
			this.elements.langCurrent,
			{
				borderRadius: '8 8 8 8',
			},
			{
				duration: 1.3,
				borderRadius: '8 8 0 0',
				ease: 'power4.out',
			},
			0
		);

		this.langTl.fromTo(
			this.elements.langOtherSvg,
			{
				autoAlpha: 0,
				y: -10,
			},
			{
				autoAlpha: 1,
				duration: 1.5,
				y: 0,
				ease: 'expo.out',
			},
			'>-0.75'
		);
	}

	toggleLanguage() {
		this.isLangOpen = !this.isLangOpen;
		if (this.isLangOpen) {
			this.langTl.duration(1);
			this.langTl.play();
			this.elements.langCurrent.classList.add('active');
		} else {
			this.langTl.duration(0);
			this.langTl.reverse();
			this.elements.langCurrent.classList.remove('active');
		}

		console.log('toggle language');
	}

	updateNav(template) {
		this.template = template;

		// Clear Classes
		// Set Active Class based on page
		if (this.template === 'home') {
			each(this.elements.contentLinks, (link) => {
				link.classList.remove('active');
			});
			this.elements.contentLinks[0].classList.add('active');
		}
	}

	show() {
		GSAP.to(this.element, {
			yPercent: 0,
			duration: 1,
			ease: 'power4.out',
			overwrite: true,
		});
		GSAP.to(this.elements.logoLinkMetrics, {
			yPercent: 0,
			duration: 1,
			ease: 'power4.out',
			overwrite: true,
		});
		GSAP.to(this.elements.langSwitch, {
			yPercent: 0,
			duration: 1,
			delay: 0.5,
			ease: 'power4.out',
			overwrite: true,
		});
		GSAP.to(this.elements.menuItem, {
			yPercent: 0,
			duration: 1,
			delay: 0.7,
			ease: 'power4.out',
			overwrite: true,
		});
	}

	hide() {
		GSAP.to(this.element, {
			yPercent: -100,
			duration: 0.7,
			ease: 'power4.in',
			overwrite: true,
		});
		GSAP.to(this.elements.logoLinkMetrics, {
			yPercent: -100,
			duration: 0.7,
			ease: 'power4.in',
			overwrite: true,
		});

		GSAP.to(this.elements.langSwitch, {
			yPercent: -200,
			duration: 0.7,
			ease: 'power4.in',
			overwrite: true,
		});
		GSAP.to(this.elements.menuItem, {
			yPercent: -200,
			duration: 0.7,
			ease: 'power4.in',
			overwrite: true,
		});
	}

	addEventListeners() {
		each(this.elements.contentLinks, (link, index) => {
			link.addEventListener('click', (e) => {
				this.navChangeIndex(index);

				window.globalAnalyticsInstance.trackEvent('Social Media', {
					action: 'Click',
					label: link.textContent,
				});
			});
		});

		this.elements.logoLinkMetrics.addEventListener('click', () => {
			window.globalAnalyticsInstance.trackEvent('Navigation', {
				action: 'Click',
				label: 'Logo',
			});
		});

		each(this.elements.emailLinks, (item) => {
			item.addEventListener('click', () => {
				window.globalAnalyticsInstance.trackEvent('Navigation', {
					action: 'Click',
					label: 'Email',
				});
			});
		});

		this.elements.langCurrent.addEventListener('click', () => {
			this.toggleLanguage();

			window.globalAnalyticsInstance.trackEvent('Navigation', {
				action: 'Click',
				label: 'Language Switch',
			});
		});

		this.elements.langOther.addEventListener('click', () => {
			// get current url
			// for example, if url is /main, replace with /${url}/main (if /works, replace with /${url}/works, etc-etc)
			// if url is /, replace with /${url}
			// if url is /ua/main change the 'ua' to 'en' or vise-versa

			const currentUrl = window.location.pathname;

			const targetLocale = this.elements.langOther.getAttribute('data-url'); // ua or en

			// if currentUrl has 'ua' or 'en'
			if (currentUrl.match(/\/(ua|en)\//)) {
				const targetPath = currentUrl.replace(
					/\/(ua|en)\//,
					`${targetLocale}/`
				);
				window.location.href = targetPath;
			} else {
				window.location.href = targetLocale;
			}
		});
	}

	onResize() {
		const w = this.elements.menuLogoMain.getBoundingClientRect().width;
		const menuWidth = 48;
		const m = (w - menuWidth) / 2;

		// this.elements.menuItem.style.margin = `0 ${m}px`;
	}
}
